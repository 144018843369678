


















































































.comeStoADS {
  text-align: center;
  margin-bottom: 20px;
  width: 70%;
}
